import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import moment from "moment";

const formatToCurrency = (amount) => {
  return (
    "₦" +
    Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
};

const FuelWallet = () => {
  const [page, setPage] = useState(1);
  const { amount } = useParams();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [payRef, setPayRef] = useState(nanoid(10));
  const [hasToken, sethasToken] = useState(false);
  const [userData, setUserData] = useState(null);

  const goToPage = async (e) => {
    e.preventDefault();
    if (name !== "" || email !== "") {
      setPage(2);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (phone !== "") {
      setIsLoading(true);
      fetch("https://api.myvivcar.com/api/auth/check", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone_number: phone }),
      })
        .then((response) => response.text())
        .then((temp) => {
          const data = JSON.parse(temp);
          console.log("Success:", data);
          if (data.used) {
            setUserData(data.data);
            setPage(2);
          } else {
            setIsLoading(false);
            setShowMore(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const initialPayment = async () => {
    setIsLoading(true);
    const payload = {
      ref: payRef,
      mobile: phone.substring(1),
      email: userData ? userData.email : email,
      amount: amount,
      first_name: userData ? userData.first_name : name.split(" ")[0],
      last_name: userData ? userData.last_name : name.split(" ")[1] || "",
      product: "4litres",
      product_type: "Buy Fuel",
      station_name: "RainOil",
    };
    console.log("initialPayment PAYLOAD", payload);
    fetch("https://carviva-wallet.herokuapp.com/api/v1/payment/initialize", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(({ payments }) => {
        // console.log("initialPayment Success:", payments);
        // setPaymentData(payments);
        initBooking();
      })
      .catch((error) => {
        console.error("initialPayment Error:", error);
      });
  };

  const initBooking = () => {
    const payload = {
      centre_id: "92788",
      provider_id: "927",
      service_id: "445",
      amount: amount,
      date: moment().format("YYYY-MM-DD"),
      payType: "Card Payment",
      ref: payRef,
      time: moment().format("HH:mm"),
      carModel: "Honda Accord Crosstour",
      carYear: "2022",
      first_name: name.split(" ")[0],
      last_name: name.split(" ")[1],
      email: email,
      phone: phone,
      state: "Lagos",
      address: "",
    };
    fetch("https://api.myvivcar.com/api/booking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.text())
      .then((temp) => {
        // const data = JSON.parse(temp.substring(1));
        // console.log("initBooking Success:", data);
        paymentWebHook();
      })
      .catch((error) => {
        console.error("initBooking Error:", error);
      });
  };

  const paymentWebHook = async () => {
    const payload = {
      transactionRef: payRef,
      status: true,
      message: "APPROVED",
    };
    console.log("paymentWebHook payload", payload);
    fetch("https://carviva-wallet.herokuapp.com/api/v1/webhook", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("paymentWebHook Success:", data);
        console.log("paymentWebHook Success:", data);
        sethasToken(true);
        // if (data.status === 500) {
        //   alert(
        //     "Your fuel token generation is delayed. Please contact support@mycarviva.com"
        //   );
        // } else {
        //   console.log("paymentWebHook Success:", data);
        //   sethasToken(true);
        // }
      })
      .catch((error) => {
        console.error("paymentWebHook Error:", error);
        sethasToken(true);
        // alert(
        //   "Your fuel token generation is delayed. Please contact support@mycarviva.com"
        // );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="overlay">
      {page === 1 ? (
        <form>
          <div className="con">
            <header className="head-form">
              <h2>Fuel Wallet</h2>
              <p>login to purchase fuel voucher.</p>
            </header>
            <br />
            <div className="field-set">
              <span className="input-item">
                <i className="fa fa-mobile"></i>
              </span>
              <input
                className="form-input"
                id="txt-input"
                type="text"
                placeholder="Your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />

              <br />

              <span className="input-item">
                <i className="fa fa-wallet"></i>
              </span>
              <input
                className="form-input"
                id="txt-input"
                type="text"
                disabled={true}
                placeholder={formatToCurrency(amount)}
              />

              {showMore && (
                <>
                  <br />
                  <span className="input-item">
                    <i class="fa fa-user"></i>
                  </span>
                  <input
                    className="form-input"
                    id="txt-input"
                    type="text"
                    placeholder="Your full name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <br />
                  <span className="input-item">
                    <i class="fa fa-envelope"></i>
                  </span>
                  <input
                    className="form-input"
                    id="txt-input"
                    type="email"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </>
              )}

              <br />
              <br />
              <button
                className="log-in sign-up"
                disabled={isLoading}
                onClick={showMore ? goToPage : handleLogin}
              >
                {isLoading ? "Please wait..." : "Continue"}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="content">
          <div className="con">
            <header className="head-form">
              <h2>Fuel Voucher</h2>
              <p>
                Tap the Buy Fuel button to purchase fuel worth{" "}
                {formatToCurrency(amount)}.
              </p>
            </header>
            <div className="row">
              <h3>
                Welcome,{" "}
                <span>
                  {userData
                    ? `${userData.first_name} ${userData.last_name}`
                    : name}
                </span>
              </h3>
              <h4>
                Wallet: <span>{formatToCurrency(userData?.wallet)}</span>
              </h4>
            </div>
            <br />
            <br />
            {hasToken ? (
              <>
                <p className="token-text">
                  Your fuel wallet transaction reference is:{" "}
                  <span>{payRef.toUpperCase()}</span>
                </p>
              </>
            ) : (
              <button
                className="log-in buy-fuel"
                disabled={isLoading}
                onClick={initialPayment}
              >
                {isLoading ? "Generating Token..." : "Buy Fuel"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FuelWallet;
