import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FuelWallet from "./FuelWallet";

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/:amount" component={FuelWallet} />
          <Route path="/" component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </div>
    </Router>
  );
};

const Home = () => {
  return (
    <div className="banner">
      <h2>Fuel Wallet</h2>
    </div>
  );
};

export default App;
